const Twitter = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.3333 5.02067C12.9322 5.19792 12.508 5.31733 12.0733 5.37533C12.5453 5.09733 12.868 4.69067 13.042 4.156C12.6108 4.41226 12.1385 4.59202 11.646 4.68733C11.222 4.22933 10.6907 4 10.0527 4C9.448 4 8.93267 4.21333 8.50533 4.64C8.29869 4.84061 8.13525 5.08136 8.02508 5.34745C7.9149 5.61355 7.86031 5.89936 7.86467 6.18733C7.86467 6.38867 7.882 6.55533 7.91667 6.68733C7.03791 6.64343 6.1786 6.41275 5.396 6.01067C4.62387 5.61805 3.94257 5.06801 3.396 4.396C3.19796 4.73021 3.09343 5.11152 3.09333 5.5C3.09333 6.292 3.40933 6.89933 4.04067 7.32267C3.714 7.316 3.36733 7.22533 2.99933 7.052V7.07267C2.99933 7.594 3.17267 8.05733 3.52 8.46333C3.86733 8.87 4.29467 9.12133 4.80133 9.21867C4.6287 9.26989 4.45002 9.29791 4.27 9.302C4.13364 9.29778 3.99775 9.28397 3.86333 9.26067C4.00533 9.69271 4.27641 10.0708 4.64 10.344C5.01133 10.6287 5.43333 10.7747 5.90533 10.7813C5.1 11.406 4.194 11.7187 3.18667 11.7187C3.00667 11.7187 2.83267 11.7087 2.666 11.6873C3.66566 12.332 4.83116 12.6723 6.02067 12.6667C6.7658 12.6722 7.50613 12.5469 8.208 12.2967C8.88867 12.05 9.472 11.7207 9.95867 11.3073C10.4438 10.8951 10.8665 10.4145 11.2133 9.88067C11.8868 8.85829 12.247 7.66156 12.25 6.43733C12.25 6.31267 12.2467 6.21867 12.2393 6.156C12.6716 5.84907 13.0426 5.464 13.3333 5.02067V5.02067ZM16 3V13C16 13.8267 15.7067 14.5333 15.12 15.12C14.5333 15.7067 13.8267 16 13 16H3C2.17333 16 1.46667 15.7067 0.88 15.12C0.293333 14.5333 0 13.8267 0 13V3C0 2.17333 0.293333 1.46667 0.88 0.88C1.46667 0.293333 2.17333 0 3 0H13C13.8267 0 14.5333 0.293333 15.12 0.88C15.7067 1.46667 16 2.17333 16 3Z'
        fill='#1DA1F2'
      />
    </svg>
  );
};

export default Twitter;
