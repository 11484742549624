import {useEffect, useRef, useState} from "react";

import Stars from "../../components/starRating/Stars";

import RequestForm from "../requestForm/RequestForm";
import SurveyForm from "../surveyForm/SurveyForm";

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

const StarRating = ({
                        defaultRating,
                        logo,
                        message,
                        stars,
                        name,
                        requestForm,
                        surveyForm,
                        platforms,
                        path,
                        cmdOverride,
                        setCmdOverride,
                    }) => {
        const [value, setValue] = useState(0); // Check if lower the threshold value. stars.threshold
        const prevValue = usePrevious(value);
        const [override, setOverride] = useState(null);
        // const [override, setOverride] = useState("request");

        const modalOpen = ((parseInt(stars?.threshold) <= value && value !== 0 && !override) || override === "request")
            || ((parseInt(stars?.threshold) > value && value !== 0 && !override) || override === "survey");

        useEffect(() => {
            if(((parseInt(stars?.threshold) <= value && value !== 0 && !override) || override === "request")) {
                console.log("Submit request")

                window.gtag("event", "formEvents", {request: "open"});
                // window.gtag("event", "formEvents", {
                //     'event_category': "formEvents",
                //         'event_label': "Opened request form",
                //             'value': "Opened request form"
                // });
            } else if (((parseInt(stars?.threshold) > value && value !== 0 && !override) || override === "survey")) {
                console.log("Submit survey")

                window.gtag("event", "formEvents", {survey: "open"});
                // window.gtag("event", "formEvents", {
                //     'event_category': "formEvents",
                //     'event_label': "Opened survey form",
                //     'value': "Opened survey form"
                // });
            }

            // this sets dark bakground to all elements within main section except open modal
            if(modalOpen) {
                const main = document.querySelector("main .tw1-relative");
                const overlay = document.createElement("div");
                overlay.setAttribute("id", "overlay");
                main.appendChild(overlay);
                overlay.className = "tw1-absolute tw1-top-0 tw1-left-0 tw1-right-0 tw1-bottom-0 tw1-bg-black/[.7] tw1-h-screen tw1-z-20";

            } else {
                document.getElementById("overlay")?.remove();
            }
        }, [modalOpen]);
        useEffect(() => {
            if (value === 0 && prevValue !== 0 && !cmdOverride) {
                setOverride(null)
            }

        }, [value, prevValue])

        useEffect(() => {
            if (cmdOverride === "none") {
                setOverride(null)
                setValue(0)
                setCmdOverride(null)
            } else {
                setOverride(cmdOverride)
            }


        }, [cmdOverride])
        // console.log(2, override, value)
        // console.log(3, cmdOverride)
        // console.log(4, ((parseInt(stars?.threshold) <= value && value !== 0 && !override) || override === "request"))
        console.log("Modal: ", modalOpen)
        return (
            <>
                {((parseInt(stars?.threshold) <= value && value !== 0 && !override) || override === "request") ? (
                    <RequestForm
                        override={override}
                        setOverride={setOverride}
                        value={value}
                        setValue={setValue}
                        requestForm={requestForm}
                        platforms={platforms}
                    />
                ) : null}
                {((parseInt(stars?.threshold) > value && value !== 0 && !override) || override === "survey") ? (
                    <SurveyForm override={override} setOverride={setOverride} path={path} value={value} setValue={setValue}
                                surveyForm={surveyForm}/>
                ) : null}
                {<section
                    className='tw1-max-w-screen-sm sm:tw1-max-w-screen-md md:tw1-max-w-screen-lg lg:tw1-max-w-screen-xl tw1-px-3 tw1-mx-auto tw1-z-10'>
                    <div className='tw1-w-full tw1-bg-white'>
                        <div
                            className='tw1-py-[23px] tw1-px-[35px] tw1-mx-auto tw1-max-w-[560px] tw1-flex tw1-flex-col tw1-items-center'>
                            <div className='tw1-max-w-[360px] tw1-mx-auto'>
                                <img
                                    style={{maxWidth: '100%', display: 'block'}}
                                    src={logo}
                                    className='tw1-mb-8 tw1-h-10 tw1-mx-auto'
                                    alt={name}
                                />
                                <p
                                    className='tw1-text-[12px] tw1-font-bold tw1-mb-1.5 tw1-text-[#6C757D] tw1-text-center'
                                    style={{lineHeight: 1.7}}
                                >
                                    {message}
                                </p>
                            </div>
                            <Stars defaultRating={defaultRating} stars={stars} setValue={setValue}/>
                        </div>
                    </div>
                </section>}
            </>
        );
    }
;

export default StarRating;
