import {Dialog} from "@headlessui/react";

import Platforms from "../../components/requestForm/Platforms";
import Close from "../../components/icons/Close";
import {Bounce} from "react-awesome-reveal";

const isStandalone = process.env.REACT_APP_IS_STANDALONE === "true";

const RequestForm = ({value, setValue, platforms, requestForm, setOverride, override}) => {
    if (!isStandalone) {
        return (
                <div
                    onClose={() => setValue(0)}
                    className='tw1-absolute tw1-z-50 tw1-w-full tw1-top-[10px] tw1-left-1/2 tw1-transform tw1--translate-x-1/2 '
                >
                    <div
                        className='tw1-inset-0 tw1-flex tw1-items-center tw1-justify-center tw1-p-4 tw1-z-50'>
                        <div
                            className='tw1-w-full tw1-rounded tw1-bg-white  tw1-max-w-[627px] tw1-rouded-[4px] tw1-relative tw1-z-50'>
                            <div className='tw1-max-w-[548px] tw1-mx-auto tw1-py-12 tw1-px-6'>
                                {requestForm?.headermessage ? (
                                    <div
                                        className='tw1-text-xl tw1-font-medium tw1-mb-6 tw1-text-center tw1-text-[#374C51]'>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: requestForm?.headermessage,
                                            }}
                                        ></div>
                                    </div>
                                ) : null}
                                <Platforms platforms={platforms}/>
                            </div>
                            <div
                                className='tw1-absolute tw1-top-4 tw1-right-4 tw1-cursor-pointer'
                                onClick={() => setValue(0)}
                            >
                                <Close/>
                            </div>
                            <div
                                onClick={e => setOverride("survey")}
                                className='tw1-border-t tw1-border-[#dae1e2] tw1-bg-[#b5bcc71a] tw1-p-7 tw1-text-xs tw1-font-medium tw1-text-center tw1-text-[#374C51]
           form-footer tw1-cursor-pointer tw1-underline'>
                                If you do not wish to post a review and would prefer to address your concerns, <span className="tw1-text-blue-500">click here.</span>
                                {/*{requestForm?.footermessage ? (*/}
                                {/*  <div*/}
                                {/*    onClick={() => setValue(1)}*/}
                                {/*    dangerouslySetInnerHTML={{*/}
                                {/*      __html: requestForm?.footermessage,*/}
                                {/*    }}*/}
                                {/*  ></div>*/}
                                {/*) : null}*/}
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
    return (
        <Dialog
            open={(value && value > 0) || !!override}
            onClose={() => setValue(0)}
            className='tw1-relative tw1-z-50'
        >
            <div
                className='tw1-fixed tw1-inset-0 tw1-flex tw1-items-center tw1-justify-center tw1-p-4 tw1-bg-gray-800 tw1-bg-opacity-50'>
                <Dialog.Panel
                    className='tw1-w-full tw1-rounded tw1-bg-white  tw1-max-w-[627px] tw1-rouded-[4px] tw1-relative'>
                    <div className='tw1-max-w-[548px] tw1-mx-auto tw1-py-12 tw1-px-6'>
                        {requestForm?.headermessage ? (
                            <Dialog.Title
                                className='tw1-text-xl tw1-font-medium tw1-mb-6 tw1-text-center tw1-text-[#374C51]'>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: requestForm?.headermessage,
                                    }}
                                ></div>
                            </Dialog.Title>
                        ) : null}
                        <Platforms platforms={platforms}/>
                    </div>
                    <div
                        className='tw1-absolute tw1-top-4 tw1-right-4 tw1-cursor-pointer'
                        onClick={() => setValue(0)}
                    >
                        <Close/>
                    </div>
                    <div
                        onClick={e => setOverride("survey")}
                        className='tw1-border-t tw1-border-[#dae1e2] tw1-bg-[#b5bcc71a] tw1-p-7 tw1-text-xs tw1-font-medium tw1-text-center tw1-text-[#374C51]
           form-footer tw1-cursor-pointer tw1-underline'>
                        If you do not wish to post a review and would prefer to address your concerns, <span className="tw1-text-blue-500">click here.</span>
                        {/*{requestForm?.footermessage ? (*/}
                        {/*  <div*/}
                        {/*    onClick={() => setValue(1)}*/}
                        {/*    dangerouslySetInnerHTML={{*/}
                        {/*      __html: requestForm?.footermessage,*/}
                        {/*    }}*/}
                        {/*  ></div>*/}
                        {/*) : null}*/}
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};

export default RequestForm;
