const Instagram = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.2421 0.128052H3.75805C2.79532 0.128052 1.87201 0.510497 1.19125 1.19125C0.510497 1.87201 0.128052 2.79532 0.128052 3.75805V12.2421C0.128052 13.2048 0.510497 14.1281 1.19125 14.8088C1.87201 15.4896 2.79532 15.8721 3.75805 15.8721H12.2421C13.2048 15.8721 14.1281 15.4896 14.8088 14.8088C15.4896 14.1281 15.8721 13.2048 15.8721 12.2421V3.75805C15.8721 2.79532 15.4896 1.87201 14.8088 1.19125C14.1281 0.510497 13.2048 0.128052 12.2421 0.128052V0.128052Z'
        fill='url(#paint0_linear_616_1094)'
      />
      <path
        d='M10.5387 3.23396C11.1365 3.23642 11.709 3.47496 12.1317 3.89764C12.5544 4.32031 12.7929 4.89288 12.7954 5.49063V10.5093C12.7931 11.1071 12.5546 11.6798 12.1319 12.1025C11.7092 12.5252 11.1365 12.7637 10.5387 12.766H5.46072C4.86303 12.7635 4.29054 12.5249 3.86797 12.1022C3.4454 11.6795 3.207 11.107 3.20472 10.5093V5.49063C3.20682 4.89294 3.44505 4.3203 3.8675 3.89748C4.28994 3.47466 4.86237 3.23593 5.46005 3.23329H10.5381L10.5387 3.23396ZM10.5387 2.18463H5.46072C3.64272 2.18463 2.15405 3.67396 2.15405 5.49129V10.5093C2.15405 12.328 3.64405 13.816 5.46072 13.816H10.5387C12.3574 13.816 13.8454 12.326 13.8454 10.5093V5.49063C13.8429 4.6144 13.4938 3.77477 12.8742 3.15518C12.2546 2.53559 11.4149 2.18642 10.5387 2.18396V2.18463Z'
        fill='white'
      />
      <path
        d='M8 6.04066C8.51965 6.04066 9.01801 6.24709 9.38546 6.61454C9.7529 6.98198 9.95933 7.48035 9.95933 8C9.95933 8.51964 9.7529 9.01801 9.38546 9.38545C9.01801 9.7529 8.51965 9.95933 8 9.95933C7.48035 9.95933 6.98199 9.7529 6.61454 9.38545C6.2471 9.01801 6.04067 8.51964 6.04067 8C6.04067 7.48035 6.2471 6.98198 6.61454 6.61454C6.98199 6.24709 7.48035 6.04066 8 6.04066V6.04066ZM8 4.99133C7.6049 4.99133 7.21366 5.06915 6.84863 5.22035C6.4836 5.37155 6.15193 5.59317 5.87255 5.87255C5.59317 6.15193 5.37155 6.4836 5.22035 6.84863C5.06915 7.21366 4.99133 7.60489 4.99133 8C4.99133 8.3951 5.06915 8.78634 5.22035 9.15136C5.37155 9.51639 5.59317 9.84806 5.87255 10.1274C6.15193 10.4068 6.4836 10.6284 6.84863 10.7796C7.21366 10.9308 7.6049 11.0087 8 11.0087C8.79795 11.0087 9.56321 10.6917 10.1274 10.1274C10.6917 9.56321 11.0087 8.79794 11.0087 8C11.0087 7.20205 10.6917 6.43678 10.1274 5.87255C9.56321 5.30831 8.79795 4.99133 8 4.99133V4.99133ZM11.074 5.57266C11.2632 5.57266 11.4446 5.49751 11.5784 5.36373C11.7122 5.22996 11.7873 5.04852 11.7873 4.85933C11.7873 4.67014 11.7122 4.4887 11.5784 4.35493C11.4446 4.22115 11.2632 4.146 11.074 4.146C10.8848 4.146 10.7034 4.22115 10.5696 4.35493C10.4358 4.4887 10.3607 4.67014 10.3607 4.85933C10.3607 5.04852 10.4358 5.22996 10.5696 5.36373C10.7034 5.49751 10.8848 5.57266 11.074 5.57266Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_616_1094'
          x1='10.4467'
          y1='16.5327'
          x2='5.55339'
          y2='-0.532616'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFDB73' />
          <stop offset='0.08' stopColor='#FDAD4E' />
          <stop offset='0.15' stopColor='#FB832E' />
          <stop offset='0.19' stopColor='#FA7321' />
          <stop offset='0.23' stopColor='#F6692F' />
          <stop offset='0.37' stopColor='#E84A5A' />
          <stop offset='0.48' stopColor='#E03675' />
          <stop offset='0.55' stopColor='#DD2F7F' />
          <stop offset='0.68' stopColor='#B43D97' />
          <stop offset='0.97' stopColor='#4D60D4' />
          <stop offset='1' stopColor='#4264DB' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Instagram;