const Facebook = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_616_1091)'>
        <path
          d='M2.92134 6.18756e-06C2.53748 -0.000784639 2.15725 0.0742381 1.80246 0.220769C1.44767 0.367299 1.12531 0.582453 0.853881 0.853881C0.582453 1.12531 0.367299 1.44767 0.220769 1.80246C0.0742381 2.15725 -0.000784639 2.53748 6.18756e-06 2.92134V13.0787C-0.000784639 13.4625 0.0742381 13.8428 0.220769 14.1976C0.367299 14.5523 0.582453 14.8747 0.853881 15.1461C1.12531 15.4176 1.44767 15.6327 1.80246 15.7792C2.15725 15.9258 2.53748 16.0008 2.92134 16H8.42667V9.74467H6.77267V7.49334H8.42601V5.56934C8.42601 4.05734 9.40401 2.66934 11.6553 2.66934C12.5667 2.66934 13.2407 2.75734 13.2407 2.75734L13.1873 4.86001C13.1873 4.86001 12.5007 4.85334 11.75 4.85334C10.938 4.85334 10.808 5.22734 10.808 5.84867V7.49267H13.252L13.1453 9.74467H10.808V16H13.0787C13.4625 16.0008 13.8428 15.9258 14.1976 15.7792C14.5523 15.6327 14.8747 15.4176 15.1461 15.1461C15.4176 14.8747 15.6327 14.5523 15.7792 14.1976C15.9258 13.8428 16.0008 13.4625 16 13.0787V2.92134C16.0008 2.53748 15.9258 2.15725 15.7792 1.80246C15.6327 1.44767 15.4176 1.12531 15.1461 0.853881C14.8747 0.582453 14.5523 0.367299 14.1976 0.220769C13.8428 0.0742381 13.4625 -0.000784639 13.0787 6.18756e-06H2.92134Z'
          fill='#1877F2'
        />
      </g>
      <defs>
        <clipPath id='clip0_616_1091'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Facebook;
