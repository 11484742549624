// import Tripadvisor from "../../../public/TripAdvisor_Logo.png";
// import Google from "../../../public/Google_logo.png";

import Tripadvisor from "../../images/TripAdvisor_Logo.png"
import Google from "../../images/Google_logo.png"

const Icon = ({ name }) => {
  switch (name) {
    case "google":
      return <img src={Google} alt='Google Logo' width={110} height={38} style={{height: 38, width: 120}} />;
    case "tripadvisor":
      return (
          <img
              style={{width: 120, height: 24}}
              src={Tripadvisor}
              alt='Tripadvisor Logo'
              // width={110}
              // height={24}
          />
      );
    default:
      return <div>Icon not found</div>;
  }
};

const Platform = ({ name, url }) => {
  return (
      <div className='tw1-p-2'>
        <a
            className='tw1-bg-[#f5f5f8] tw1-py-7 tw1-px-[18px] tw1-flex tw1-items-center tw1-justify-center tw1-h-[96px]'
            href={url}
            target='_blank'
            rel='noreferrer noopener'
        >
          <Icon name={name} />
        </a>
      </div>
  );
};

const Platforms = ({ platforms }) => {
  return (
      <div className='tw1-flex tw1-items-center tw1-flex-wrap tw1-justify-center'>
        {platforms?.map(({ name, url }) => {
          return <Platform name={name} key={name} url={url} />;
        })}
      </div>
  );
};

export default Platforms;
